import { createWebHistory, createRouter } from "vue-router";

const routes = [{
    path: "/:id?",
    name: "Home",
    component: () =>
        import('@/views/HomePage.vue'),
    meta: {
        title: `四维时空数据库`
    }
},
{
    path: "/search",
    name: "Search",
    component: () =>
        import('@/views/ViewSearch.vue'),
    meta: {
        title: `搜索`
    }
},
{
    path: "/location/:id/",
    name: "Location",
    component: () =>
        import('@/views/ViewLocation.vue'),
    meta: {
        title: `建筑地点`
    }
},
{
    path: "/building/:id/",
    name: "Building",
    component: () =>
        import('@/views/ViewBuilding.vue'),
    meta: {
        title: `时空片段`
    }
},
{
    path: "/segment/:id/",
    name: "Segment",
    component: () =>
        import('@/views/ViewSegment.vue'),
    meta: {
        title: `时空片段信息`
    }
},
{
    path: "/person/:id/",
    name: "Person",
    component: () =>
        import('@/views/ViewPerson.vue'),
    meta: {
        title: `历史人物`
    }
},
{
    path: "/history/:id/",
    name: "History",
    component: () =>
        import('@/views/ViewHistory.vue'),
    meta: {
        title: `历史事件`
    }
},
{
    path: "/organize/:id/",
    name: "Organize",
    component: () =>
        import('@/views/ViewOrganize.vue'),
    meta: {
        title: `关联单位`
    }
},
{
    path: "/map/",
    name: "Map",
    component: () =>
        import('@/views/ViewMap.vue'),
    meta: {
        title: `地图检索`
    }
},
{
    path: "/:region?/oldmap/:id?/:mode?/",
    name: "ViewOldMap",
    component: () =>
        import('@/views/ViewOldMap.vue'),
    meta: {
        title: `历史地图`
    }
},
{
    path: "/:region?/oldmaplist/",
    name: "ViewOldMapList",
    component: () =>
        import('@/views/ViewOldMapList.vue'),
    meta: {
        title: `历史地图集`
    }
},
{
    path: "/mgr/login",
    name: "Login",
    component: () =>
        import('@/views/UserLogin.vue'),
    meta: {
        title: `管理员登录`
    }
},
{
    path: "/mgr/wizard",
    name: "Wizard",
    component: () =>
        import('@/views/DataEntryWizard.vue'),
    meta: {
        // 页面标题title
        title: `数据录入向导`
    }
},
{
    path: "/mgr/oldmap",
    name: "OldMap",
    component: () =>
        import('@/views/OldMap.vue'),
    meta: {
        // 页面标题title
        title: `老地图管理`
    }
},
{
    path: "/mgr/georeferencer/:id/",
    name: "GeoReferencer",
    component: () =>
        import('@/views/OldMapGeoReferencer.vue'),
    meta: {
        // 页面标题title
        title: `老地图配准`
    }
},
{
    path: "/mgr/oldmap_upload/:region?/",
    name: "OldMapUpload",
    component: () =>
        import('@/views/OldMapUpload.vue'),
    meta: {
        // 页面标题title
        title: `老地图管理`
    }
},
{
    path: "/mgr/setting",
    name: "Setting",
    component: () =>
        import('@/views/UserSetting.vue'),
    meta: {
        title: `信息设置`
    }
},
{
    path: "/mgr/password",
    name: "Password",
    component: () =>
        import('@/views/ChangePassword.vue'),
    meta: {
        title: `修改密码`
    }
},
{
    path: "/404",
    name: "404",
    component: () =>
        import('@/views/NotFound.vue'),
    meta: {
        title: `没有找到网页`
    }
},
{
    path: "/:catchAll(.*)", // 此处需特别注意至于最底部
    redirect: "/404"
}
];

const router = createRouter({
    // https://next.router.vuejs.org/guide/essentials/history-mode.html
    history: createWebHistory(),
    routes,
});

export default router;