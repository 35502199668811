<template>
  <header class="p-3 mb-3 border-bottom">
    <div class="container">
      <div
        class="
          d-flex
          flex-wrap
          align-items-center
          justify-content-center justify-content-lg-start
        "
      >
        <router-link
          :to="{ name: 'Home' }"
          class="
            d-flex
            align-items-center
            mb-2 mb-lg-0
            text-dark text-decoration-none
          "
        >
          <img
            class="bi me-3"
            width="41"
            height="32"
            role="img"
            aria-label="时空记忆"
            src="../assets/logo.png"
          /><b class="fs-3">时空记忆</b>
        </router-link>

        <ul
          class="
            nav
            col-12 col-lg-auto
            me-lg-auto
            mb-2
            justify-content-center
            mb-md-0
            ms-4
          "
        >
          <li>
            <router-link
              :to="{ name: 'Search', query: { type: 'location' } }"
              class="nav-link px-2"
              :class="$route.name == 'Search' ? 'link-secondary' : 'link-dark'"
            >
              搜索
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Map' }"
              class="nav-link px-2"
              :class="$route.name == 'Map' ? 'link-secondary' : 'link-dark'"
            >
              地图定位
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ViewOldMapList' }"
              class="nav-link px-2"
              :class="$route.name == 'ViewOldMap' ? 'link-secondary' : 'link-dark'"
            >
              历史地图
            </router-link>
          </li>
        </ul>

        <div class="d-flex align-items-center">
          <form
            class="w-100"
            v-if="$route.name != 'Search'"
            @submit.prevent="search"
          >
            <input
              type="search"
              class="form-control"
              placeholder="输入关键字搜索"
              aria-label="Search"
              v-model="keyword"
            />
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  setup() {
    const keyword = ref("");
    let router = useRouter();
    const search = () => {
      router.push({
        name: "Search",
        query: {
          keyword: keyword.value,
        },
      });
    };
    return {
      keyword,
      search,
    };
  },
};
</script>

<style scoped>
</style>