import axios from "axios";

const ACCESS_KEY = "auth_token_access"
const REFRESH_KEY = "auth_token_refresh"

function Auth() {
  this.user = null;
  this._reflesh_token();
}

/**
 * 刷新token
 */
Auth.prototype._reflesh_token = function () {
  let self = this;
  setTimeout(() => {
    if (localStorage.getItem(REFRESH_KEY)) {
      axios
        .post("/api/token/refresh/", {
          refresh: localStorage.getItem(REFRESH_KEY),
        })
        .then((response) => {
          localStorage.setItem(ACCESS_KEY, response.data.access);
        })
        .catch(() => {
          self.logout();
        });
    }
    self._reflesh_token();
  }, 1000 * 60 * 4);
}

/**
 * 登录
 * @param {String} username 
 * @param {String} pasword 
 * @returns Promise
 */
Auth.prototype.login = function (username, password) {
  let promise = new Promise((resolve, reject) => {
    axios
      .post("/api/token/", { username, password })
      .then((response) => {
        localStorage.setItem(ACCESS_KEY, response.data.access);
        localStorage.setItem(REFRESH_KEY, response.data.refresh);
        resolve(response.data)
      })
      .catch((e) => {
        reject(e)
      });
  });
  return promise;
};

/**
 * 登出
 */
Auth.prototype.logout = function () {
  localStorage.removeItem(ACCESS_KEY);
  localStorage.removeItem(REFRESH_KEY);
  this.user = null;
};

/**
 * 获取用户信息
 */
Auth.prototype.get_user = function (refresh = false) {
  let self = this;
  let promise = new Promise((resolve, reject) => {
    if (!localStorage.getItem(ACCESS_KEY)) {
      resolve(null)
    }
    if (!refresh) {
      if (self.user != null) {
        resolve(self.user);
        return
      }
    }
    axios
      .get("/api/auth/info/")
      .then((response) => {
        if (response.data.username) {
          self.user = response.data;
          resolve(self.user)
        } else {
          self.logout()
          reject('NOT_LOGIN')
        }
      })
      .catch(() => {
        reject('ERROR')
      });
  })
  return promise
}

var auth = new Auth();

// 添加请求拦截器
axios.interceptors.request.use((config) => {
  if (config.method != 'get' || config.url.toString().startsWith('/api/auth/') || config.url.toString().startsWith('/api/token/')) {
    //只在需要处理的方法和路径中
    if (localStorage.getItem(ACCESS_KEY) && !config.headers.common["Authorization"]) {
      // 添加一个钩子，处理认证信息
      config.headers.common["Authorization"] = "Bearer " + localStorage.getItem(ACCESS_KEY);
    }
  }
  return config;
});

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status == 401) {
      // 清空登录信息
      auth.logout();
    }
    return Promise.reject(error)
  }
);

export default auth;