<template>
  <div class="container">
    <footer
      class="
        d-flex
        flex-wrap
        justify-content-between
        align-items-center
        py-3
        my-4
        border-top
      "
    >
      <div class="col-md-4">
        <div>
          <img
            src="../assets/zhangxiang_logo.jpg"
            height="30"
            alt="藏象科技"
            class="me-1"
          />Copyright © 2022 藏象科技.
          <span class="text-nowrap">All Rights Reserved</span>
        </div>
        <div>网站联系人：隋先生</div>
        <div>联系方式: 13840490199</div>
        <div>
          电子邮箱: <a href="mailto:20086947@qq.com">20086947@qq.com</a>
        </div>
      </div>

      <a
        class="
          col-md-4
          d-flex
          align-items-center
          justify-content-center
          mb-3 mb-md-0
          me-md-auto
          link-dark
          text-decoration-none
          foot-logo
        "
      >
        <img class="bi me-2" width="240" src="../assets/logo_text.png" />
      </a>

      <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item">
          <a class="nav-link px-2 text-muted text-nowrap"
            >辽ICP备2022004196号-1</a
          >
        </li>
        <li class="nav-item">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010602000734"
            class="nav-link px-2 text-muted text-nowrap"
            ><img src="../assets/beian.png" />
            辽公网安备 21010602000734号
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
  name: "TimemarkingFooter",
};
</script>

<style scoped>
@media (max-width: 768px) {
  .foot-logo {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
</style>