<template>
  <header class="p-3 mb-3 border-bottom">
    <div class="container">
      <div
        class="
          d-flex
          flex-wrap
          align-items-center
          justify-content-center justify-content-lg-start
        "
      >
        <router-link
          :to="{ name: 'Home' }"
          class="
            d-flex
            align-items-center
            mb-2 mb-lg-0
            text-dark text-decoration-none
          "
        >
          <img
            class="bi me-3"
            width="41"
            height="32"
            role="img"
            aria-label="时空记忆"
            src="../assets/logo.png"
          /><b class="fs-3">时空记忆</b>
        </router-link>
        <ul
          class="
            nav
            col-12 col-lg-auto
            me-lg-auto
            mb-2
            justify-content-center
            mb-md-0
            ms-4
          "
        >
          <template v-if="user">
            <li>
              <router-link
                :to="{ name: 'Wizard' }"
                class="nav-link px-2"
                :class="routeName == 'Wizard' ? 'link-secondary' : 'link-dark'"
              >
                数据录入向导
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'OldMap' }"
                class="nav-link px-2"
                :class="routeName == 'OldMap' ? 'link-secondary' : 'link-dark'"
              >
                老地图管理
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'Setting' }"
                class="nav-link px-2"
                :class="routeName == 'Setting' ? 'link-secondary' : 'link-dark'"
              >
                管理员信息设置
              </router-link>
            </li>
          </template>
        </ul>

        <ul v-if="user" class="nav">
          <li class="nav-item">
            <router-link
              :to="{ name: 'Setting' }"
              class="nav-link link-dark px-2"
              >您好，{{ nickname }} !</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Password' }" class="nav-link px-2"
              >修改密码</router-link
            >
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link px-2" @click="logout">注销</a>
          </li>
        </ul>
        <!-- <router-link
          v-else
          :to="{ name: 'Login' }"
          class="btn btn-outline-primary me-2"
        >
          登录
        </router-link> -->
      </div>
    </div>
  </header>
</template>

<script>
import { onMounted, ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

import auth from "@/composables/Auth";

export default {
  computed: {
    nickname: {
      get() {
        if (this.user == null) {
          return "";
        }
        if (this.user.first_name) {
          return this.user.first_name;
        } else {
          return this.user.username;
        }
      },
    },
  },
  data: function () {
    const user = ref(null);
    const route = useRoute();
    const router = useRouter();
    const routeName = ref("");

    function get_user() {
      auth
        .get_user()
        .then((user_data) => {
          user.value = user_data;
          if (!user_data && route.name != "Login") {
            router.push({
              name: "Login",
            });
          }
        })
        .catch(() => {
          if (route.name != "Login") {
            router.push({
              name: "Login",
            });
          }
        });
    }

    onMounted(() => {
      routeName.value = router.currentRoute.value.name;
      get_user();
    });

    watch(route, (to) => {
      if (to.path.startsWith("/mgr/")) {
        get_user();
      }
      routeName.value = to.name;
    });

    const logout = () => {
      auth.logout();
      router.push({
        name: "Login",
      });
    };

    return {
      user,
      logout,
      routeName,
    };
  },
};
</script>

<style scoped>
</style>