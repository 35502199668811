import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"

axios.defaults.baseURL = "https://www.timemarking.com/";
// axios.defaults.baseURL = "http://localhost:8001/";
// axios.defaults.baseURL = "http://localhost/";

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = `时空记忆 - ${to.meta.title}`
    }
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
});

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.min.js"

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(router)

// 天地图
import VueTianditu from "vue-tianditu";
const TIANDITU_TK = '3ffb7c62a2a244fd3259546ccce7bdff';
app.use(VueTianditu, {
    v: "4.0",
    tk: TIANDITU_TK,
});
app.config.globalProperties.$TIANDITU_TK = TIANDITU_TK;

app.use(BootstrapVue3)
app.mount('#app')

// createApp(App).use(router).mount('#app')